import Dropbox from "components/atoms/icons/Dropbox";
import GoogleDrive from "components/atoms/icons/GoogleDrive";
import Info from "components/atoms/icons/Info";
import OneDrive from "components/atoms/icons/OneDrive";
export const FULL_USERNAME_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9]+([_.-]?[a-zA-Z0-9])*$/;
export const HOME_PAGE = import.meta.env.VITE_ROUTER_HOMEPAGE ?? "";
const HOST = import.meta.env.VITE_HOST || "localhost";
const API_URL = import.meta.env.VITE_API_URL || "/api/v2/front";
const PROTOCOL = import.meta.env.VITE_PROTOCOL || "http";
const PORT = import.meta.env.VITE_PORT;
export const BASE_API_URL = `${PROTOCOL}://${HOST}${
  PORT ? `:${PORT}` : ""
}${API_URL}`;
export const BASE_URL = `${PROTOCOL}://${HOST}${PORT ? `:${PORT}` : ""}`;
export const PERSISTED_STATE_PREFIX = "state-";
export const TOKEN_PERSIST_KEY = "keep-token";
export const USER_STORAGE_KEY = "user"; // index.html uses this value to not show bg if user is logged in
export const THEME_STORAGE_KEY = "theme";
export const DEFAULT_STORAGE: Storage = window.sessionStorage;
export const DEFAULT_ICON_SIZE = "24px";
export const MAX_NOTIFICATIONS_STACKED = 3;
export const DEFAULT_TAKE = 20;
export const DEFAULT_TREE_PRELOAD_DEPTH = 3;
export const MAX_DOWNLOAD_STATUS_DELAY = 10 * 60 * 60 * 1000;
export const PIN_LENGTH = 6;
export const DEFAULT_SCREENS_TAKE = 5; // on screen map, better name here would help
export const DEFAULT_TILE_PROVIDER = undefined;
export const MERCURE_HUB_URL =
  import.meta.env.VITE_MERCURE_HUB_URL ??
  "https://panel2.sdsdev.pl/.well-known/mercure";
export const CHII_HOST =
  import.meta.env.VITE_CHII_HOST || "192.168.111.239:8001";
export const LAST_URL_KEY = "last_url";
export const USER_SETTINGS_VERSION = 9;

export const createGoogleAuthUrl = (
  clientId: string,
  name: string,
  token: string
): string => {
  const value = `{"name": "${name}", "token": "${token}"}`;
  const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=${clientId}&redirect_uri=${BASE_API_URL}/connection/oauth2/code_exchange/google_drive&state=${value}&scope=https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/userinfo.profile&include_granted_scopes=true&prompt=select_account`;

  return url;
};

export const createOneDriveAuthUrl = (
  clientId: string,
  name: string,
  token: string
): string => {
  const value = `{"name": "${name}", "token": "${token}"}`;
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${BASE_API_URL}/connection/oauth2/code_exchange/onedrive&scope=files.read files.read.all offline_access&response_mode=query&state=${value}`;

  return url;
};

export const createDropboxAuthUrl = (
  clientId: string,
  name: string,
  token: string
): string => {
  const value = `{"name": "${name}", "token": "${token}"}`;
  const url = `https://www.dropbox.com/oauth2/authorize?state=${value}&scope=account_info.read files.metadata.read files.content.read&token_access_type=offline&response_type=code&redirect_uri=${BASE_API_URL}/connection/oauth2/code_exchange/dropbox&client_id=${clientId}`;

  return url;
};

export const typesConnectionMap = {
  ftp: {
    name: "Ftp",
    icon: Info,
  },
  google_drive: {
    name: "Google drive",
    icon: GoogleDrive,
  },
  onedrive: {
    name: "OneDrive",
    icon: OneDrive,
  },
  dropbox: {
    name: "Dropbox",
    icon: Dropbox,
  },
};
// export const DEFAULT_TILE_PROVIDER = (x: number, y: number, z: number, dpr?: number) =>`http://localhost/tile/${z}/${x}/${y}${dpr && dpr >= 2 ? "@2x" : ""}.png`
